import React, { Suspense, useContext } from 'react'
import { Canvas } from 'react-three-fiber'
import { Loader } from '@react-three/drei'

import WindowContext from '@context/window'
import Lighting from './lighting'
import Paper from './paper'
import Cloth from './cloth'

const DiscoWall = ({ className }) => {
  const w = useContext(WindowContext)
  return (
    <>
      <Canvas className={ className }
        style={ { position: 'absolute', width: `${w.w}px`, height: `${w.h}px` } }
        gl={ { alpha: true, antialias: true, logarithmicDepthBuffer: true } }
        camera={ { fov: 75, position: [ 0, 0, 40 ] } }
      >
        <Suspense fallback={ null }>
          <Paper position={ [ -150, 12, -16 ] } />
          <Cloth position={ [ -2, 3, 0 ] }
            w={ w }
          />
        </Suspense>
        <Lighting />
      </Canvas>
    </>
  )
}

export default DiscoWall
