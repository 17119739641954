import React, { useRef } from 'react'
import { useFrame } from 'react-three-fiber'

const Lighting = () => {
  const moveLight = useRef(null)
  useFrame(state => {
    const time = state.clock.getElapsedTime()
    moveLight.current.position.x = moveLight.current.position.x - Math.sin(time / 3) / 300 // + 0.001
    moveLight.current.position.y = moveLight.current.position.y - Math.cos(time / 3) / 300 // - 0.001
    // console.log(time, moveLight.current.position.x, moveLight.current.position.y)
  })
  return (
    <>
      <directionalLight ref={ moveLight }
        position={ [ 0, 0, 1 ] }
        intensity={ 0.23 }
      />
      <ambientLight intensity={ 0.88 } />
      { /* <spotLight position={ [ 10, 10, 10 ] }
        angle={ 0.15 }
        penumbra={ 1 }
      /> */ }
      { /* <pointLight position={ [ -10, -10, -10 ] } /> */ }
    </>
  )
}

export default Lighting
