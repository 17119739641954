import React, { useRef } from 'react'
import { FrontSide, LinearFilter, MirroredRepeatWrapping, sRGBEncoding } from 'three'
import { MeshWobbleMaterial, useTexture } from '@react-three/drei'

import DisconeAss from '@raster/discone-duo.png'

const Cloth = (props) => {
  const mesh = useRef()

  const discoTexture = useTexture(DisconeAss)
  discoTexture.encoding = sRGBEncoding
  discoTexture.wrapS = discoTexture.wrapT = MirroredRepeatWrapping
  discoTexture.minFilter = LinearFilter
  discoTexture.repeat.set((props.w.w / 200 * 15), (props.w.h / 800 * 15))

  return (
    <mesh { ...props }
      ref={ mesh }
    >
      <planeBufferGeometry attach="geometry"
        args={ [
          (props.w.w / 200 * 15),
          (props.w.h / 200 * 15),
          12,
          6,
        ] }
      />
      <MeshWobbleMaterial attach="material"
        factor={ 0.042 }// Strength, 0 disables the effect (default=1)
        speed={ 1 } // Speed (default=1)
        metalness='1'
        roughness='0.5'
        side={ FrontSide }
        map={ discoTexture }
        transparent={ true }
        opacity={ 1 }
      />
      { /* <meshPhysicalMaterial color='#000000'
        metalness='1'
        roughness='0.5'
      /> */ }
      { /* <MeshDistortMaterial attach="material"
        distort={ 0.25 } // Strength, 0 disables the effect (default=1)
        speed={ 10 } // Speed (default=1)
        metalness='1'
        roughness='0.5'
      /> */ }
      { /* <meshStandardMaterial color={ '#820263' } /> */ }
    </mesh>
  )
}

export default Cloth

