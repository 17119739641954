import React from 'react'

import Page from '@layout'
import DiscoWall from '@components/disconium'

const DiscoWallPage = () => {
  return (
    <Page id='disco'>
      <DiscoWall className='disco' />
    </Page>
  )
}

export default DiscoWallPage
