import React, { useEffect, useRef, useState } from 'react'
import { DoubleSide } from 'three'
import { useFrame } from 'react-three-fiber'
import { MeshDistortMaterial } from '@react-three/drei'

const Paper = (props) => {
  const mesh = useRef()
  const [ floatDir, setFloatDir ] = useState(true)

  useFrame(() => {
    if (floatDir) {
      mesh.current.position.x += 0.4
      if (mesh.current.position.x > 150) {
        setFloatDir(false)
        mesh.current.position.y = Math.random() * 72 - 36
        // console.log(mesh.current.position.y)
      }
    } else {
      mesh.current.position.x -= 0.4
      if (mesh.current.position.x < -150) {
        setFloatDir(true)
        mesh.current.position.y = Math.random() * 72 - 36
        // console.log(mesh.current.position.y)
      }
    }
  })
  useEffect(() => {
    mesh.current.rotation.z = -Math.PI / 1.25
    mesh.current.rotation.x = Math.PI / 2
    mesh.current.rotation.y = Math.PI / 6
  }, [])

  return (
    <mesh { ...props }
      ref={ mesh }
      // scale={ state.isHovered ? [ 1.5, 1.5, 1.5 ] : [ 1, 1, 1 ] }
      // onClick={ (e) => setState({ ...state, isActive: !state.isActive }) }
      // onPointerOver={ (e) => setState({ ...state, isHovered: true }) }
      // onPointerOut={ (e) => setState({ ...state, isHovered: false }) }
    >
      <planeBufferGeometry attach="geometry"
        args={ [ 8.5, 11 ] }
      />
      <MeshDistortMaterial attach="material"
        distort={ 0.76 } // Strength, 0 disables the effect (default=1)
        speed={ 0.5 } // Speed (default=1)
        metalness='1'
        roughness='0.5'
        side={ DoubleSide }
        opacity={ 0.23 }
        color='#dfe6f7'
      />
    </mesh>
  )
}

export default Paper

